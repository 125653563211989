import axiosI from '../../api/base.api';
import { UserFullDto } from '../../types';
import { buildApiMutationHook } from '../../utils/api';

export type LoginWithPasswordParams =
  | {
      email: string;
      password: string;
    }
  | {
      phoneNo: string;
      password: string;
    };

export type LoginDto = {
  accessToken: string;
  user: UserFullDto;
};

export const useLoginWithPasswordMutation = buildApiMutationHook<LoginWithPasswordParams, LoginDto>(
  (data) => axiosI.post('/login/withPassword', data),
);

export type LoginFacebookParams = {
  facebookAccessToken: string;
};

export const useLoginFacebookMutation = buildApiMutationHook<LoginFacebookParams, LoginDto>(
  (data) => axiosI.post('/login/facebook', { access_token: data.facebookAccessToken }),
);

export type VerifyOtpParams = {
  otp: number;
};

export type VerifyOtpResult = {
  verified: boolean;
};

export const useVerifyOtpMutation = buildApiMutationHook<VerifyOtpParams, VerifyOtpResult>((data) =>
  axiosI.post('/login/forgotPassword/verifyOtp', data),
);
