import classNames from 'classnames';
import { HTMLInputTypeAttribute, ReactElement, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { BiCheck, BiErrorCircle } from 'react-icons/bi';
import CustomTooltip from './CustomTooltip';

type Props = {
  label?: string | ReactElement;
  registerFn?: UseFormRegisterReturn;
  error?: FieldError | undefined;
  placeholder: string;
  className?: string;
  value?: string;
  toolTipMessage?: string;
  customError?: ReactElement;
  type?: HTMLInputTypeAttribute;
  maxLength?: number;
  onChange?: (event: { target: any }) => void;
};

const CustomInput = ({
  registerFn,
  error,
  placeholder,
  className,
  label,
  value,
  toolTipMessage,
  customError,
  type,
  maxLength,
  onChange,
}: Props) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  let functions = onChange ? { ...registerFn, onChange: onChange } : { ...registerFn };
  return (
    <>
      {label && <div className="input-label">{label}</div>}
      <div
        className={classNames(className, 'w-full relative my-3', {
          'error-div': !!error,
        })}
      >
        <input
          className={classNames('custom-register-input', {
            'error-div': !!error,
          })}
          placeholder={placeholder}
          type={type}
          maxLength={maxLength}
          {...functions}
        />

        <div className={`absolute right-4 top-4`}>
          <BiCheck className={`${!error && value ? 'flex' : 'hidden'} w-5 h-5`} />
          <BiErrorCircle
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
            className={`${error ? 'flex' : 'hidden'} w-5 h-5 text-danger-darker`}
          />
          {showToolTip && <CustomTooltip message={toolTipMessage} />}
        </div>
        {error?.type === 'custom' && customError}
      </div>
    </>
  );
};

export default CustomInput;
