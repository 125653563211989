/* eslint-disable camelcase */
import { RefinementCtx, z, ZodDefault, ZodError, ZodOptional, ZodType } from 'zod';
import { validateNameRegexp } from './matchRegex';
import { unknownErrorToMessage } from './unknowErrorToMessage';

export type AnyZod<Output, Input> = ZodType<Output, any, Input>;

function mkRefinementForSchema<Output, Input>(
  schema: AnyZod<Output, Input>,
): (arg: Input, ctx: RefinementCtx) => any {
  return (v, ctx) => {
    const result = schema.safeParse(v);
    if (result.success) return;

    for (const error of result.error.errors) {
      ctx.addIssue(error);
    }
  };
}

/**
 * Difference from normal zod transform is that it accepts tranforms that can throw.
 */
export function applyTransform<T1, T2, T3>(
  schema: AnyZod<T2, T1>,
  transform: (v: T2) => T3,
): AnyZod<T3, T1> {
  return schema
    .superRefine((v, ctx) => {
      try {
        transform(v);
      } catch (err) {
        ctx.addIssue({
          code: 'custom',
          message: unknownErrorToMessage(err),
        });
      }
    })
    .transform(transform);
}

function applyTransformWithFallback<T1, T2, T3, TFallback>(
  schema: AnyZod<T2, T1>,
  transform: (v: T2) => T3,
  fallback: (v: T2) => TFallback,
): AnyZod<T3 | TFallback, T1> {
  return schema.transform((v) => {
    try {
      return transform(v);
    } catch (err) {
      return fallback(v);
    }
  });
}

export function z_fallback<TInput, TOutput, TFallback>(
  schema: AnyZod<TOutput, TInput>,
  fallback: (v: TInput) => TFallback,
): AnyZod<TOutput | TFallback, TInput> {
  return applyTransformWithFallback(z.any(), (v) => schema.parse(v), fallback);
}

export function z_combine<T1, T2, T3>(
  schema1: AnyZod<T2, T1>,
  schema2: AnyZod<T3, T2>,
): AnyZod<T3, T1> {
  return schema1.superRefine(mkRefinementForSchema(schema2)).transform(schema2.parse);
}

export function z_combineArray<T1, T2, T3>(
  schema1: AnyZod<T2[], T1>,
  schema2: AnyZod<T3, T2>,
): AnyZod<T3[], T1> {
  return applyTransform(schema1, (vs) => vs.map((v) => schema2.parse(v)));
}

type ZodComposeTransformSchema<Output, Input> =
  | void
  | AnyZod<Output, Input>
  | ZodDefault<AnyZod<Output, Input>>;
type ZodComposeTransformResult<RawInput, Output, Schema> = Schema extends ZodDefault<
  AnyZod<Output, unknown>
>
  ? AnyZod<Output, Output | RawInput | undefined>
  : Schema extends void | AnyZod<Output, unknown>
  ? AnyZod<Output, Output | RawInput>
  : never;

function composeTransform<Output, Input, RawInput>({
  initialTransform,
  defaultSchema,
}: {
  initialTransform: AnyZod<Input, RawInput>;
  defaultSchema: AnyZod<Output, Input>;
}) {
  const transform = z.union([initialTransform, defaultSchema]);
  return function z_rawInputToOutput<
    Schema extends ZodComposeTransformSchema<Output, Input> = void,
  >(schema?: Schema): ZodComposeTransformResult<RawInput, Output, Schema> {
    if (schema instanceof ZodDefault) {
      return z_combine(transform.optional(), schema) as ZodComposeTransformResult<
        RawInput,
        Output,
        Schema
      >;
    } else {
      return z_combine(transform, schema ?? defaultSchema) as ZodComposeTransformResult<
        RawInput,
        Output,
        Schema
      >;
    }
  };
}

type ZodComposeTransformStrictResult<RawInput, Output, Schema> = Schema extends ZodDefault<
  AnyZod<Output, unknown>
>
  ? AnyZod<Output, RawInput | undefined>
  : Schema extends void | AnyZod<Output, unknown>
  ? AnyZod<Output, RawInput>
  : never;

function composeTransformStrict<Output, Input, RawInput>({
  initialTransform,
  defaultSchema,
}: {
  initialTransform: AnyZod<Input, RawInput>;
  defaultSchema: AnyZod<Output, Input>;
}) {
  return function z_rawInputToOutput<
    Schema extends ZodComposeTransformSchema<Output, Input> = void,
  >(schema?: Schema): ZodComposeTransformStrictResult<RawInput, Output, Schema> {
    if (schema instanceof ZodDefault) {
      return z_combine(initialTransform.optional(), schema) as ZodComposeTransformStrictResult<
        RawInput,
        Output,
        Schema
      >;
    } else {
      return z_combine(
        initialTransform,
        schema ?? defaultSchema,
      ) as ZodComposeTransformStrictResult<RawInput, Output, Schema>;
    }
  };
}

export const z_stringToNumber = composeTransform({
  initialTransform: z.string().transform(Number),
  defaultSchema: z.number(),
});

export const z_stringToNumberStrict = composeTransformStrict({
  initialTransform: z.string().transform(Number),
  defaultSchema: z.number(),
});

export const z_stringToDate = composeTransform({
  initialTransform: z.string().transform((s) => new Date(s)),
  defaultSchema: z.date(),
});

export const z_stringToBoolean = () => z.enum(['true', 'false']).transform((v) => v === 'true');

export const z_stringToNullIfEmpty = composeTransform({
  initialTransform: z.string().transform((v) => (v === '' ? null : v)),
  defaultSchema: z.string().nullable(),
});

export const z_stringToObject = <Output>(schema: AnyZod<Output, unknown>) =>
  z_combine(
    applyTransform(z.string(), (v) => JSON.parse(v) as unknown),
    schema,
  );

export const z_stringNonEmpty = () => z.string().min(1);

export const z_stringToTrue = () => z.literal('true').transform(() => true as const);

const trimString = (u: unknown) => (typeof u === 'string' ? u.trim() : u);
export const z_stringToNameRequired = ({ nonEmptyMessage }: { nonEmptyMessage: string }) => {
  return z.preprocess(
    trimString,
    z
      .string()
      .nonempty(nonEmptyMessage)
      .regex(validateNameRegexp, 'Only characters and numbers are allowed'),
  );
};
export const z_stringToName = () => {
  // TODO: how to add regex rule to optional string
  return z.preprocess(trimString, z.string().optional());
};
/**
 * Originated from https://github.com/colinhacks/zod/issues/372#issuecomment-826380330
 * Passes if type inferred from schema is assignable to already defined type.
 * (So it allows to define more narrow types).
 */
export const schemaForType =
  <T>() =>
  <S extends z.ZodType<T, any, any>>(arg: S) => {
    return arg;
  };

export function isZodError(error: unknown): error is ZodError {
  return error instanceof ZodError;
}

/**
 * Helper function to define zod validation on page route parameters.
 * Enforces input types to be `string` because all route parameters are strings.
 * @example
 * const PageParams = z_params({
 *   userId: z_stringToNumberStrict(),
 *   id: z_stringToNumberStrict().optional(),
 * });
 *
 * export function Page() {
 *   const { userId, id } = PageParams.parse(useParams());
 * }
 */
export function z_params<
  // TODO: add support for unions
  Args extends {
    [key: string]:
      | AnyZod<any, string | undefined>
      | ZodOptional<AnyZod<any, string>>
      | ZodDefault<AnyZod<any, string>>;
  },
>(args: Args) {
  return z.object(args);
}
