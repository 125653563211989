import { PasswordValidationErrors, PasswordValidations } from '@/features/auth/signUp.types';
import { BiCheck, BiX } from 'react-icons/bi';
interface Props {
  showPasswordInfo: boolean;
  errors: PasswordValidationErrors | PasswordValidations | undefined;
}

const PasswordRules = ({ showPasswordInfo, errors }: Props) => {
  return (
    <>
      {showPasswordInfo && (
        <div className="ant-card-body ant-card-bordered bg-white mt-2">
          <div className="psw-hint-card">
            <div className="flex" style={{ color: !errors?.minimum6Char ? 'black' : 'red' }}>
              {!errors?.minimum6Char ? <BiCheck size={20} /> : <BiX size={20} />}
              <p>Minimum 6 digit of alphanumeric password required.</p>
            </div>
          </div>
          <div className="psw-hint-card">
            <div className="flex" style={{ color: !errors?.capitalChar ? 'black' : 'red' }}>
              {!errors?.capitalChar ? <BiCheck size={20} /> : <BiX size={20} />}
              <p>Min. 1 capital alphabet required.</p>
            </div>
          </div>
          <div className="psw-hint-card">
            <div className="flex" style={{ color: !errors?.numericChar ? 'black' : 'red' }}>
              {!errors?.numericChar ? <BiCheck size={20} /> : <BiX size={20} />}
              <p>Min. 1 numeric character required.</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordRules;
