import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';
import { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import { BiCheck, BiErrorCircle } from 'react-icons/bi';
import { useToggle } from 'react-use';
import PasswordRules from '../../../components/passwordRules';
import { PasswordValidationErrors } from '../signUp.types';
import CustomTooltip from './CustomTooltip';

type Props = {
  label?: string;
  registerFn?: UseFormRegisterReturn;
  error: PasswordValidationErrors | FieldError | undefined;
  placeholder: string;
  className?: string;
  value?: string;
  toolTipMessage?: string;
  showPasswordRules?: boolean;
  customError?: ReactElement;
};

const CustomPasswordInput = ({
  registerFn,
  error,
  placeholder,
  className,
  label,
  showPasswordRules,
  toolTipMessage,
  value,
}: Props) => {
  const [showToolTip, setShowToolTip] = useState<boolean>(false);
  const [showPasswordInfo, togglePasswordInfo] = useToggle(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {label && <div className="input-label">{label}</div>}
      <div
        className={classNames(className, 'w-full relative my-3', {
          'error-div': !!error,
        })}
      >
        <input
          type={showPassword ? 'text' : 'password'}
          className={classNames('custom-register-input', {
            'error-div': !!error,
          })}
          placeholder={placeholder}
          {...registerFn}
        />
        <div className={`absolute right-5 top-3`}>
          {!showPassword ? (
            <EyeInvisibleOutlined onClick={() => setShowPassword(!showPassword)} />
          ) : (
            <EyeOutlined className="check-icon" onClick={() => setShowPassword(!showPassword)} />
          )}
        </div>

        {showPasswordRules ? (
          value ? (
            <div className={`absolute right-10 top-4`}>
              <BiErrorCircle
                onClick={() => togglePasswordInfo()}
                className={`flex w-5 h-5 ${error ? 'text-danger-darker' : ''}`}
              />
            </div>
          ) : null
        ) : (
          <div className={`absolute right-10 top-4`}>
            <BiCheck className={`${!error && value ? 'flex' : 'hidden'} w-5 h-5`} />
            <BiErrorCircle
              onMouseEnter={() => setShowToolTip(true)}
              onMouseLeave={() => setShowToolTip(false)}
              className={`${error ? 'flex' : 'hidden'} w-5 h-5 text-danger-darker`}
            />
            {!showPasswordRules && showToolTip && <CustomTooltip message={toolTipMessage} />}
          </div>
        )}
        {showPasswordRules && value && (
          <PasswordRules
            showPasswordInfo={showPasswordInfo}
            errors={error as PasswordValidationErrors}
          />
        )}
      </div>
    </>
  );
};

export default CustomPasswordInput;
