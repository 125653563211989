import { message } from 'antd';
import ReactFacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
  ReactFacebookLoginProps,
} from 'react-facebook-login';
import { FaFacebookF } from 'react-icons/fa';
import { login } from '../auth.service';
import { useLoginFacebookMutation } from '../login.api';

const facebookAppId = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID;
if (!facebookAppId) {
  throw new Error(
    'Missing NEXT_PUBLIC_FACEBOOK_APP_ID env variable, you can copy it from .env.example',
  );
}

function isErrorFacebookResponse(
  response: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
): response is ReactFacebookFailureResponse {
  return 'status' in response;
}

export type FacebookLoginProps = {
  returnUrl?: string;
  isLogin: boolean;
};

export const FacebookLogin = ({ returnUrl, isLogin }: FacebookLoginProps) => {
  const loginFacebookMutation = useLoginFacebookMutation({
    onSuccess({ user, accessToken }) {
      login({ user, accessToken, returnUrl });
    },
  });

  const responseFacebook: ReactFacebookLoginProps['callback'] = async (response) => {
    if (isErrorFacebookResponse(response)) {
      message.error('Error facebook auth.');
      return;
    }
    const facebookAccessToken = response.accessToken;

    await loginFacebookMutation.mutateAsync({
      facebookAccessToken,
    });
  };

  return (
    <ReactFacebookLogin
      appId={facebookAppId}
      scope="public_profile,email"
      fields="id,email,first_name,last_name"
      callback={responseFacebook}
      cssClass="btn-new m-auto btn-facebook"
      icon={<FaFacebookF className="facebook-icon" />}
      textButton={
        (
          <span>{`${isLogin ? 'Log In' : 'Sign Up'}`} with Facebook</span>
        ) as any /* wrong typings */
      }
    />
  );
};
