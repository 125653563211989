export const minimum6Char = (value) => value.trim().length >= 6;

export const specialChar = (value) => /[!@#$%^&*)(+=._-]+/.test(value.trim());

export const capitalChar = (value) => /(?=.*[A-Z])/.test(value.trim());

export const smallAlpha = (value) => /(?=.*[a-z])/.test(value.trim());

export const numericChar = (value) => /(?=.*\d)/.test(value.trim());

export const validateEmail = (email) => /^\w.+@[a-zA-Z_.]+?\.[a-zA-Z]{2,3}$/.test(email?.trim());

export const validateDiscountCodeRegex = /^[a-zA-Z0-9]*$/;

export const validateNameRegexp = /^[a-zA-Z0-9 ]*[a-zA-Z0-9]$/;

export const validateName = (name) => {
  if (name.trim().length === 0) {
    return true;
  }
  if (!validateNameRegexp.test(name)) {
    // letters, numbers and space(not at the end)
    return true;
  } else return false;
};

export const onlyNumeric = (value) => /^[0-9,-]*$/.test(value.trim());

export const isUrl = (value) => /^(http|https):/.test(value.trim());

export const isUrlImage = (value) => /\.(jpe?g|png|gif|bmp)$/i.test(value.trim());

export const validateWebsiteRegexp =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
export const validateInstagramRegexp = /.*instagr.*/;
export const validateFacebookRegexp = /(.*fb.*|.*facebook.*)/;
export const validateTwitterRegexp = /.*/;
export const validateTiktokRegexp = /.*/;
export const validateWhatNotRegexp = /.*/;
